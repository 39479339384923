import { Event, Job, JobAttributeValue, JobType } from 'types';
import dayjs from 'dayjs';
import { rrulestr } from 'rrule';
import { dateGMT, luxonDT } from 'utils/dates';
import { Filters } from 'contexts/CalendarContext';
import { Interval } from 'luxon';

export function groupJobsByDate(dates: Date[], jobs: Job[]|undefined): Record<string, Job[]> {
  const gmtDates = dates.map(x => dateGMT(x, true));
  const firstDate = luxonDT(dayjs.min(gmtDates).toISOString()).startOf('day');
  const lastDate = luxonDT(dayjs.max(gmtDates).toISOString()).endOf('day');

  const initialValue = gmtDates.reduce((grouped, date) => {
    grouped[date.format('YYYY-MM-DD')] = [];

    return grouped;
  }, {} as Record<string, Job[]>);

  if (!jobs) {
    return initialValue;
  }

  return jobs.reduce((groupedByDate, job) => {
    const days = firstDate && lastDate
      ? getAssignedResourceDates(job, { startDate: firstDate.toJSDate(), endDate: lastDate.toJSDate() })
      : [];

    days.forEach(day => {
      const formattedDate = luxonDT(day, true).toFormat('yyyy-MM-dd');
      if (groupedByDate.hasOwnProperty(formattedDate)) {
        groupedByDate[formattedDate].push(job);
      }
    });

    return groupedByDate;
  }, initialValue);
}

export function groupEventsByDate(dates: Date[], events: Event[]|undefined): Record<string, Event[]> {
  const gmtDates = dates.map(x => dateGMT(x));
  const firstDate = luxonDT(dayjs.min(gmtDates).toISOString()).startOf('day');
  const lastDate = luxonDT(dayjs.max(gmtDates).toISOString()).endOf('day');

  const initialValue = gmtDates.reduce((grouped, date) => {
    grouped[date.format('YYYY-MM-DD')] = [];

    return grouped;
  }, {} as Record<string, Event[]>);

  if (!events) {
    return initialValue;
  }

  return events.reduce((groupedByDate, event) => {
    const days = firstDate && lastDate
      ? getAssignedResourceDates(event, { startDate: firstDate.toJSDate(), endDate: lastDate.toJSDate() })
      : [];

    days.forEach(day => {
      const formattedDate = luxonDT(day, true).toFormat('yyyy-MM-dd');
      if (groupedByDate.hasOwnProperty(formattedDate)) {
        groupedByDate[formattedDate].push(event);
      }
    });

    return groupedByDate;
  }, initialValue);
}

export function getAssignedResourceDates(occurrence: Job|Event, betweenDates?: { startDate: Date, endDate: Date }): Date[] {
  if (occurrence.recurring) {
    const rule = rrulestr(occurrence.recurring);

    if (betweenDates) {
      // Currently all RRULE start/until are stored with the TZ which is Mountain Standard or Mountain Daylight.
      // This causes any recurrences that have a start and until that are in different offsets to display off by a day
      // The byhour option for those TZ are 6/7 so by bumping to 12 it any shifts don't won't change for CFC.
        // TODO: With white label coming too much of our date logic is tied to America/Denver so this will have to be re-evaluated
      rule.options = { ...rule.options, byhour: [12] };
      return rule.between(betweenDates.startDate, betweenDates.endDate, true);
    }

    return rule.all();
  }

  const occurrenceDays = [];

  const startDate = luxonDT(occurrence.startDate);
  if (!betweenDates || Interval.fromDateTimes(betweenDates.startDate, betweenDates.endDate).contains(startDate)) {
    occurrenceDays.push(startDate.toJSDate());
  }

  if (occurrence.endDate) {
    let dateCursor = startDate.plus({ days: 1 });
    const endDate = luxonDT(occurrence.endDate);

    while (endDate >= dateCursor) {
      if (!betweenDates || Interval.fromDateTimes(betweenDates.startDate, betweenDates.endDate).contains(dateCursor)) {
        occurrenceDays.push(dateCursor.toJSDate());
      }

      dateCursor = dateCursor.plus({ days: 1 });
    }
  }

  return occurrenceDays;
}

export function deserializeAttributeValue(value?: string): any {
  if (typeof value === 'undefined') {
    return undefined;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    return value.toString();
  }
}

export function serializeAttributeValue(value: any): string {
  return JSON.stringify(value);
}

export function jobMatchesFilters(
  job: Job,
  startDate: Date | null,
  endDate: Date | null,
  filters: Filters,
  installJobType: JobType | undefined,
  warrantyJobType: JobType | undefined
) {
  const installTradeIds = filters.installTrades || [];
  const warrantyTradeIds = filters.warrantyTrades || [];
  const jobStatusIds = filters.jobStatuses || [];
  const projectManagerIds = filters.projectManagers || [];
  const warehouseIds = filters.warehouses || [];
  const installFilter = installTradeIds.length > 0;
  const warrantyFilter = warrantyTradeIds.length > 0;
  const noProjectManagerSet = projectManagerIds.some(id => id === -1);
  const noWarehouseSet = warehouseIds.some(id => id === -1);

  let jobTypeAndTradeMatch: boolean | undefined = false;
  if (installFilter && warrantyFilter) {
    jobTypeAndTradeMatch = (job.jobType?.id === installJobType?.id && (job.trade && installTradeIds.includes(job.trade.id)))
      || (job.jobType?.id === warrantyJobType?.id && (job.trade && warrantyTradeIds.includes(job.trade.id)));
  } else if (installFilter) {
    jobTypeAndTradeMatch = job.jobType?.id === installJobType?.id && (job.trade && installTradeIds.includes(job.trade.id));
  } else if (warrantyFilter) {
    jobTypeAndTradeMatch = job.jobType?.id === warrantyJobType?.id && (job.trade && warrantyTradeIds.includes(job.trade.id));
  } else {
    jobTypeAndTradeMatch = true;
  }

  // Default to true so if there are no jobStatusIds to check, this passes
  let jobStatusMatch: boolean | undefined = true;
  if (jobStatusIds.length > 0) {
    jobStatusMatch = job.jobStatuses && job.jobStatuses.map(status => status.id).some(jobStatusId => jobStatusIds.includes(jobStatusId));
  }

  const jobProjectManagers = (job.propertyAddress?.projectManagers && job.propertyAddress?.projectManagers.length > 0) ?
    job.propertyAddress?.projectManagers : (job.propertyAddress?.subdivision?.projectManagers || []);
  const jobHasProjectManagers = jobProjectManagers.length > 0;

  // Default to true so if there are no projectManagerIds to check, this passes
  let projectManagerMatch: boolean | undefined = true;
  if ((projectManagerIds.length > 0 || noProjectManagerSet) ) {
    if (projectManagerIds.length > 1 && noProjectManagerSet) {
      projectManagerMatch = !jobHasProjectManagers || jobProjectManagers
        .map(projectManager => projectManager.id)
        .some(projectManagerId => projectManagerIds.includes(projectManagerId));
    } else if (noProjectManagerSet) {
      projectManagerMatch = !jobHasProjectManagers;
    } else {
      projectManagerMatch = jobProjectManagers
        .map(projectManager => projectManager.id)
        .some(projectManagerId => projectManagerIds.includes(projectManagerId));
    }
  }

  let warehouseMatch: boolean | undefined = true;
  if (warehouseIds.length > 1 && noWarehouseSet) {
    warehouseMatch = !job.warehouse || warehouseIds.includes(job?.warehouse.id);
  } else if (noWarehouseSet) {
    warehouseMatch = !job.warehouse;
  } else if (warehouseIds.length > 0) {
    warehouseMatch = job.warehouse && warehouseIds.includes(job.warehouse.id);
  }

  const jobStartDate = job.startDate ? new Date(job.startDate) : null;
  const jobEndDate = job.endDate ? new Date(job.endDate) : null;
  const datesMatch = jobStartDate && startDate && jobEndDate && endDate
    ? (jobStartDate >= startDate && jobStartDate <= endDate) ||
      (jobEndDate >= startDate && jobEndDate <= endDate)
    : false;

  return jobTypeAndTradeMatch && jobStatusMatch && projectManagerMatch && warehouseMatch && datesMatch;
}

export function eventMatchesFilters(
  event: Event,
  startDate: Date | null,
  endDate: Date | null,
  filters: Filters,
  installJobType: JobType | undefined,
  warrantyJobType: JobType | undefined
) {
  const installTradeIds = filters.installTrades || [];
  const warrantyTradeIds = filters.warrantyTrades || [];
  const installFilter = installTradeIds.length > 0;
  const warrantyFilter = warrantyTradeIds.length > 0;

  let calendarResourceTypeAndTradeMatch: boolean | undefined = false;
  if (installFilter && warrantyFilter) {
    calendarResourceTypeAndTradeMatch = (event.calendarResource.jobType?.id === installJobType?.id && (event.calendarResource.trade && installTradeIds.includes(event.calendarResource.trade.id)))
      || (event.calendarResource.jobType?.id === warrantyJobType?.id && (event.calendarResource.trade && warrantyTradeIds.includes(event.calendarResource.trade.id)));
  } else if (installFilter) {
    calendarResourceTypeAndTradeMatch = event.calendarResource.jobType?.id === installJobType?.id && (event.calendarResource.trade && installTradeIds.includes(event.calendarResource.trade.id));
  } else if (warrantyFilter) {
    calendarResourceTypeAndTradeMatch = event.calendarResource.jobType?.id === warrantyJobType?.id && (event.calendarResource.trade && warrantyTradeIds.includes(event.calendarResource.trade.id));
  } else {
    calendarResourceTypeAndTradeMatch = true;
  }

  const eventStartDate = event.startDate ? new Date(event.startDate) : null;
  const eventEndDate = event.endDate ? new Date(event.endDate) : null;
  const datesMatch = eventStartDate && startDate && eventEndDate && endDate
    ? (eventStartDate >= startDate && eventStartDate <= endDate) ||
    (eventEndDate >= startDate && eventEndDate <= endDate)
    : false;

  return calendarResourceTypeAndTradeMatch && datesMatch;
}

export function areFiltersEmpty(filters: Filters): boolean {
  return (
    filters.installTrades.length === 0 &&
    filters.warrantyTrades.length === 0 &&
    filters.warehouses.length === 0 &&
    filters.projectManagers.length === 0 &&
    filters.jobStatuses.length === 0
  );
}

export function displayJobAttribute(jobAttributeValue: JobAttributeValue, isAdmin: boolean = false): boolean {
  if (jobAttributeValue.jobAttribute?.displayOnCard) {
    if (jobAttributeValue.jobAttribute.adminOnly && !isAdmin) {
      return false;
    }

    // Do not display checkbox JobAttributes if they are unchecked
    if (jobAttributeValue.jobAttribute.fieldType === 'checkbox' && jobAttributeValue.value === 'false') {
      return false;
    } else if (jobAttributeValue.jobAttribute.fieldType === 'file_upload' && !jobAttributeValue.value.includes('/api/files')) {
      return false;
    }

    return true;
  }

  return false;
}

export function filterEmptyJobAttributes(jobAttributeValue: JobAttributeValue, isAdmin: boolean = false): boolean {
  if (jobAttributeValue.jobAttribute?.adminOnly && !isAdmin) {
    return false;
  } else if (jobAttributeValue.jobAttribute?.fieldType === 'checkbox' && jobAttributeValue.value === 'false') {
    return false;
  } else if (jobAttributeValue.jobAttribute?.fieldType === 'file_upload' && !jobAttributeValue.value.includes('/api/files')) {
    return false;
  }

  return true;
}
